import { NgModule } from '@angular/core';
import { filterNotNull, FullUser, RoutesProvider } from '@adeprez/ionstack';
import { EDIT, LIST, NEW } from 'src/app/model/constants';

export const SIGN_UP_ROUTE = 'inscription';
export const SIGN_IN_ROUTE = 'connexion';
export const NOTIFS_ROUTE = 'notifications';
export const TRACKING_ROUTE = 'suivi';
export const LABEL_ROUTE = 'etiquette';
export const SHIPPING_REQ_ROUTE = 'expeditions';
export const TRIP_ROUTE = 'voyages';
export const VERIFY_ROUTE = 'verification';
export const REQUEST_POOL_ROUTE = 'pool';

const r = (p: string) => '/' + p;

export interface PDFRouteOptions {
  width?: number;
  height?: number;
  orientation?: 'horizontal' | 'vertical';
  format?: string;
  margin?: string;
}

const routes: RoutesProvider = {
  signUp: [r(SIGN_UP_ROUTE)],
  signIn: [r(SIGN_IN_ROUTE)],
  notifs: [r(NOTIFS_ROUTE)],
  pdf: (url: string, options?: PDFRouteOptions) => ({
    path: ['/pdf'],
    query: { r: url, ...filterNotNull(options) },
  }),

  profile: ['/profil'],
  chat: (convId?: number) => ({
    path: ['/chat'],
    query: filterNotNull({ chat: convId }),
  }),
  verification: (then?: string) => ({
    path: ['/profil', VERIFY_ROUTE],
    query: filterNotNull({ then }),
  }),

  adminUser: (u: number | FullUser) =>
    u == null ? null : ['/admin/users', typeof u === 'number' ? u : u.id],

  meTraveler: ['/profil', 'voyageur'],
  meShipper: ['/profil', 'expediteur'],

  traveler: (slug: string) => ['/voyageurs', slug],
  shipper: (slug: string, query?: any) => ({
    path: ['/expediteurs', slug],
    query,
  }),

  shippings: [r(SHIPPING_REQ_ROUTE), LIST],
  shipping: (id: number) => [r(SHIPPING_REQ_ROUTE), id],
  editShipping: (id?: number | NEW) => [r(SHIPPING_REQ_ROUTE), id ?? NEW, EDIT],
  label: (id: number) => [r(SHIPPING_REQ_ROUTE), id, LABEL_ROUTE],

  trips: [r(TRIP_ROUTE), LIST],
  trip: (id: number | NEW) => [r(TRIP_ROUTE), id],
  editTrip: (id?: number | NEW) => [r(TRIP_ROUTE), id ?? NEW, EDIT],
  afterTrip: ['/avant-mon-depart'],

  shipRequest: (shippingId: number, shipRequestId) => ({
    path: [r(SHIPPING_REQ_ROUTE), shippingId],
    query: { req: shipRequestId },
  }),
  tripShipRequest: (tripId: number, shipRequestId) => [
    r(TRIP_ROUTE),
    tripId,
    SHIPPING_REQ_ROUTE,
    shipRequestId,
  ],

  tracking: (trackingNumber?: string, code?: string) => ({
    path: [r(TRACKING_ROUTE), trackingNumber ?? '-'],
    query: filterNotNull({ code }),
  }),

  requestPool: (
    origin: number,
    destination: number,
    from: string,
    to: string,
    max: number
  ) => ({
    path: [r(REQUEST_POOL_ROUTE)],
    query: { origin, destination, from, to, max },
  }),
};

@NgModule({
  providers: [{
    provide: RoutesProvider,
    useValue: routes,
    multi: true
  }]
})
export class RoutesModule { }