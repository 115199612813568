import { Injectable } from '@angular/core';
import { Notif, NotifRoute, NotifRouteProvider, RouteService } from '@adeprez/ionstack';
import { REQUEST_POOL_ROUTE } from '../routes.module';

@Injectable({
  providedIn: 'root',
})
export class NotifRoutesService implements NotifRouteProvider {
  constructor(private routeService: RouteService) {}

  getRoute(notif: Notif): NotifRoute | void {
    if (!notif.meta) {
      return;
    }

    if (notif.type === 'pool') {
      return this.routeService.getRoute(
        'requestPool',
        notif.meta.originId,
        notif.meta.destinationId,
        notif.meta.from,
        notif.meta.to,
        notif.meta.max
      );
    }

    if ('tracking' in notif.meta) {
      return this.routeService.getRoute(
        'tracking',
        notif.meta.tracking,
        notif.meta.rcode
      );
    }

    if ('shipRequestId' in notif.meta) {
      if ('tripId' in notif.meta) {
        return this.routeService.getRoute(
          'tripShipRequest',
          notif.meta.tripId,
          notif.meta.shipRequestId
        );
      }
      return this.routeService.getRoute(
        'shipRequest',
        notif.meta.shippingId,
        notif.meta.shipRequestId
      );
    }

    if ('shippingId' in notif.meta) {
      return this.routeService.getRoute('shipping', notif.meta.shippingId);
    }

    if ('tripId' in notif.meta) {
      return this.routeService.getRoute('trip', notif.meta.tripId);
    }
  }
}
